import { B2C_LOGIN_STATE_ENDPOINT, B2C_LOGOUT_URL } from "./constants";
import { track } from "@telia/b2x-google-analytics";
import { fetchCIAMURL } from "@telia/mt-react-hooks";

export const isLoggedIn = async (): Promise<boolean> => {
  const res = await fetch(B2C_LOGIN_STATE_ENDPOINT);

  try {
    return res.json();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.info("Error checking logged in state Mitt Telia");
    return false;
  }
};

export const login = async (targetUrl = window.location.pathname) => {
  track("login_initiate", {
    method: "bank_id",
    login_target: "mitt_telia",
    click_text: targetUrl.includes("mitt-telia") ? "mitt_telia" : "logga_in",
  });

  const url = await fetchCIAMURL({ redirectUrl: targetUrl });

  if (url) {
    window.location.href = url;
  }
};

export const logout = (targetUrl?: string) => {
  track("logout_complete", {
    login_target: "mitt-telia",
    logout_method: "auto",
  });
  window.location.href = targetUrl ?? B2C_LOGOUT_URL;
};
