import { login as loginB2c, logout as logoutB2c, isLoggedIn as isLoggedInB2c } from "./b2c";
import { login as loginB2b, logout as logoutB2b, isB2B, isLoggedIn as isLoggedInB2b } from "./b2b";
import { AuthenticationType, LoggedInSegmentsType } from "./constants";

type loginOrLogoutParams = AuthenticationType | keyof typeof AuthenticationType;

export const loggedInSegment = async (): Promise<
  LoggedInSegmentsType & { isLoggedIn: boolean }
> => {
  const [B2B, B2C] = await Promise.all([isLoggedInB2b(), isLoggedInB2c()]);
  return { B2B, B2C, isLoggedIn: B2B || B2C };
};

export const isLoggedIn = async (): Promise<boolean> => {
  if (isB2B()) {
    return isLoggedInB2b();
  }
  return isLoggedInB2c();
};

export const handleLoginOrLogoutByAuthType = async (
  authType: loginOrLogoutParams,
  targetUrl?: string
) => {
  if (authType && authType === AuthenticationType.B2C) {
    if (await isLoggedInB2c()) {
      logoutB2c(targetUrl);
    } else {
      await loginB2c(targetUrl);
    }
    return;
  }

  if ((authType && authType === AuthenticationType.B2B) || isB2B()) {
    const isLoggedIn = await isLoggedInB2b();

    if (isLoggedIn) {
      await logoutB2b(targetUrl);
    } else {
      await loginB2b(targetUrl);
    }
  }
};

export const handleLoginOrLogout = async (targetUrl?: string) => {
  const loggedIn = await isLoggedIn();
  if (isB2B()) {
    if (loggedIn) {
      await logoutB2b(targetUrl);
    } else {
      await loginB2b(targetUrl);
    }
  } else {
    if (loggedIn) {
      logoutB2c(targetUrl);
    } else {
      await loginB2c(targetUrl);
    }
  }
};
