import { LOGGED_IN_STATUS_UPDATE, LoggedInSegmentsType } from "./constants";

type LoggedInStatusUpdate = {
  isLoggedIn: boolean;
} & LoggedInSegmentsType;

const loggedInUpdate = (isLoggedIn: boolean, segments: LoggedInSegmentsType): void => {
  if (window) {
    const event = new CustomEvent<LoggedInStatusUpdate>(LOGGED_IN_STATUS_UPDATE, {
      detail: {
        isLoggedIn,
        ...segments,
      },
    });
    window.dispatchEvent(event);
  }
};

export const setLoggedIn = (isLoggedIn: boolean, segments?: LoggedInSegmentsType): void => {
  loggedInUpdate(isLoggedIn, segments || { B2B: undefined, B2C: undefined });
};

type Callback = (isLoggedIn: boolean, segments?: LoggedInSegmentsType) => void;

export const subscribe = (callback: Callback): void => {
  window.addEventListener(LOGGED_IN_STATUS_UPDATE, ((evt: CustomEvent<LoggedInStatusUpdate>) => {
    const { isLoggedIn, ...segments } = evt.detail;
    callback(isLoggedIn, segments);
  }) as EventListener);
};
