export const B2C_LOGIN_URL = "/privat/mitt-telia/logga-in";
export const B2C_LOGOUT_URL = "/.api/auth-provider-tse-b2c/logout";
export const B2C_LOGIN_STATE_ENDPOINT = "/.api/springfield-login/login/isloggedin";
export const B2C_LOGIN_REDIRECT = "/mitt-telia/start";

export const LOGGED_IN_STATUS_UPDATE = "loggedInStatusUpdate";

export enum AuthenticationType {
  B2B = "B2B",
  B2C = "B2C",
}

export type LoggedInSegmentsType = {
  [AuthenticationType.B2B]: boolean | undefined;
  [AuthenticationType.B2C]: boolean | undefined;
};
