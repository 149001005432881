import { corpLogin } from "@telia/b2b-rest-client";
import { track } from "@telia/b2x-google-analytics";

export const isB2B = () => window?.location?.pathname?.startsWith("/foretag");

export const isLoggedIn = async () => {
  try {
    const { isLoggedIn } = await corpLogin.IsLoggedInControllerService.isLoggedIn();
    return Boolean(isLoggedIn);
  } catch {
    // eslint-disable-next-line no-console
    console.info("Error checking logged in state MyBusiness");
    return false;
  }
};

export const login = async (targetUrl?: string) => {
  const { redirectUrl } = await corpLogin.LoginControllerService.initiateLoginUrl({
    targetUrl: targetUrl,
  });
  if (redirectUrl) {
    track("login_initiate", {
      step_number: 1,
      step_label: "login_initiate",
      login_target: "mybusiness",
    });
    window.location.href = redirectUrl;
  }
};

export const logout = async (targetUrl?: string) => {
  const { redirectUrl } = await corpLogin.LogoutControllerService.initiateLogout();
  if (redirectUrl) {
    track("logout_complete", {
      logout_method: "auto",
      login_target: "mybusiness",
    });
    window.location.href = targetUrl ?? redirectUrl;
  }
};
